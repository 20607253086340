// export  const baseUrl="https://auditorium.wizzohost.in/server"

const storedLgpath = localStorage.getItem("lgpath");
export const loginPath = "ur_d";

// LOCALHOST URL
// export const homeUrl= "http://localhost:5173/"
// export const baseUrl = "http://localhost:3008";
// export const redirectUrl = storedLgpath
//   ? `http://localhost:5173${storedLgpath}`
//   : "http://localhost:5173/";


// // WIZZO MAIN SERVER
export const homeUrl = "https://one.wizzo.in/"; //AWS EC2
export const baseUrl = "https://server.wizzo.in"; // AWS EC2
// AWS EC2
export const redirectUrl = storedLgpath
  ? `https://one.wizzo.in${storedLgpath}`
  : "https://one.wizzo.in";


// // PRODUCTION SERVER
// export const homeUrl= "https://productioncloud.wizzo.in/"
// export const baseUrl = "http://productionbackend.wizzo.in";
// export const redirectUrl = storedLgpath
//   ? `https://productioncloud.wizzo.in${storedLgpath}`
//   : "https://productioncloud.wizzo.in";
